import os_welcomekit from '../../../src/shared/assets/images/os-welcomekit.svg'
import os_team_img from '../../../src/shared/assets/images/os-team-img.svg'
import os_team_img2 from '../../../src/shared/assets/images/os-team-img1.jpg'
import mission_img from '../../shared/assets/images/about-us-mission.jpg'
import robo_img from '../../shared/assets/images/about-us-vision.jpg'
import card_group from '../../shared/assets/images/card-group.svg'
import globe from '../../shared/assets/images/card-globe.svg'
import pool_group from '../../shared/assets/images/card-talent.svg'
import tech_competence from '../../shared/assets/images/card-tech-ompetence.svg'
import cost_optimization from '../../shared/assets/images/card-cost-optmization.svg'
import awards_img from "../../shared/assets/images/award-img.jpg";
import awards_img1 from "../../shared/assets/images/award-img1.svg"
import circle_dot from "../../shared/assets/images/circles.svg"
import red_rect from "../../shared/assets/images/red-rect.svg"
import blue_rect from "../../shared/assets/images/blue-rect.svg"
import leadeship_bg from "../../shared/assets/images/leadership-bg.png";
import linkdin_icon from "../../shared/assets/images/linkedIn.svg";
import mail_icon from "../../shared/assets/images/mail.svg";
import os_ashav_img from "../../shared/assets/images/os-ashav-img.svg";
import square_red from "../assets/images/square-red.svg";
import rect_blue from "../assets/images/rect-blue.svg";
import card from "../assets/images/card-bg.png"
import landing1 from '../assets/images/landing1.jpg';
import landing2 from '../assets/images/landing2.jpg';
import landing3 from '../assets/images/landing3.jpg';
import technology_carouel from '../assets/images/technology-carousel.jpg';
import scroll_down from '../assets/gifs/scroll-down.gif';
import right_arrow from '../assets/images/white-right-arrow.svg';
import landing1_mob from '../assets/images/landing1-mob.jpg';
import landing2_mob from '../assets/images/landing2-mob.jpg';
import landing3_mob from '../assets/images/landing3-mob.jpg';
import card_img from '../assets/images/card-img.svg';
import blueBox from '../assets/images/box.svg';
import Circles from '../assets/images/circles.svg';
import netImg from '../assets/images/net-mng-syst.jpg';
import prev from '../assets/images/previous.png';
import next from '../assets/images/next.png';
import blueBg from '../assets/images/blue-bg.svg';
import SDE from '../assets/gifs/sde.gif';
import Operations from '../assets/gifs/operations.gif';
import ui from '../assets/gifs/ui-ux.gif';
import cloud from '../assets/gifs/cloud.gif';
import qa from '../assets/gifs/qa.gif';
import consulting from '../assets/images/consultancy.svg';
import hospitality from '../assets/images/hospitality.svg';
import group from '../assets/images/group-17917.svg';
import technology from '../assets/images/technology.svg';
import transportaion from '../assets/images/transportation.svg';
import healthcare from '../assets/images/healthcare.svg';
import realEstate from '../assets/images/tech-enabled-spaces.svg';
import engage from '../assets/images/employment-community-engagement.svg';
import organization from '../assets/images/non-governmental-organization.svg';
import Food from '../assets/images/food-beverage.svg';
import darkBlueBox from '../assets/images/blue-box.svg';
import redBox from '../assets/images/red-box.svg';
import circlesVert from '../assets/images/circles-vert.svg';
import redVertLine from '../assets/images/red-vert-line.svg';
import SeBg from '../assets/images/se-bg.png';
import SeImg from '../assets/images/se-img.png'
import mobAppDev from '../assets/images/mobile-app-dev.svg';
import webAppDev from '../assets/images/web-app-dev.svg';
import productEngg from '../assets/images/product-engineering-icon.svg';
import softwareEngg from '../assets/images/software-engineering.svg';
import POC from '../assets/images/proof-of-concepts.svg';
import Java from '../assets/images/java.svg';
import Python from '../assets/images/python.svg';
import Angular from '../assets/images/angular-js.svg';
import Node from '../assets/images/node-js.svg';
import ReactNative from '../assets/images/react-native.svg';
import HTML5 from '../assets/images/html-5.svg';
import CSS3 from '../assets/images/css3.svg';
import Vue from '../assets/images/vue-js.svg';
import Quotes from '../assets/images/quote.svg';
import facebook from '../assets/images/footer-facebook.svg';
import Instagram from '../assets/images/footer-instagram.svg';
import Linkdein from '../assets/images/footer-linkedIn.svg';
import mail from '../assets/images/social-media-mail.svg';
import wmpLogo from '../assets/images/logo.png';
import BULLET from '../assets/images/bulllet.jpg';
import services_footer_img from '../assets/images/services-footer-bg.jpg';
import team_bg from '../assets/images/team-bg.jpg';
import kub_img_mv_1 from "../../shared/assets/images/kub-mv-img-1.png";
import kub_img_mv_2 from "../../shared/assets/images/kub-mv-img-2.png";
import kub_img_mv_3 from "../../shared/assets/images/kub-mv-img-3.png";
import kub_img_mv_4 from "../../shared/assets/images/kub-mv-img-4.png";
import blog_card_img_1 from "../../shared/assets/images/blog-card-img-1.svg";
import blog_card_img_2 from "../../shared/assets/images/blog-card-img-2.jpg";
import blog_card_img_3 from "../../shared/assets/images/blog-card-img-3.svg";
import blog_card_img_5 from "../../shared/assets/images/blog-card-img-5.svg";
import blog_card_img_6 from "../../shared/assets/images/blog-card-img-6.jpg";
import jayant_blog_img from "../../shared/assets/images/jayant-blog-img.svg";
import vikas_blog_img from "../../shared/assets/images/vikas-blog-img.svg";
import samyakt_blog_img from "../../shared/assets/images/samyakt-blog-img.svg";
import mohit_blog_img from "../../shared/assets/images/mohit-blog-img.svg";
import unique_blog_img from "../../shared/assets/images/unique-blog-img.svg";
import harpreet_blog_img from "../../shared/assets/images/harpareet-blog-img.svg";
import kawaldeep_blog_img from "../../shared/assets/images/kawaldeep-blog-img.jpg";
import kekashri_blog_img from "../../shared/assets/images/kekashri-blog-img.svg";
import mukul_blog_img from "../../shared/assets/images/mukul-blog-img.jpg";
import culture_people_img from "../../shared/assets/images/culture-people-img.png";
import culture_innovation_img from "../../shared/assets/images/culture-innovation.png";
import culture_building_img from "../../shared/assets/images/culture-building-img.svg";
import culture_family_img from "../../shared/assets/images/culture-family-img.jpg";
import culture_people_mob from "../../shared/assets/images/culture-people-mob.png";
import culture_innovation_mob from "../../shared/assets/images/culture-innovation-mob.png";
import culture_building_mob from "../../shared/assets/images/culture-building-mob.svg";
import culture_family_mob from "../../shared/assets/images/culture-family-mob.png";
import network_management_bg from "../../shared/assets/images/network-management-background.jpg";
import technology_bg from "../../shared/assets/images/technology-background.jpg";
import network_mgt_web from "../../shared/assets/images/web-network-management.png";
import technology_web from "../../shared/assets/images/web-technology.png";
import network_mgt_mob from "../../shared/assets/images/mob-network-mgmt.png";
import technology_mob from "../../shared/assets/images/mob-technology.png";
import devops_bg from "../../shared/assets/images/devops-bg.jpg";
import devops_img from '../../shared/assets/images/devops-img.jpg';
import cloud_infra from '../../shared/assets/images/cloud-infra.svg';
import cloud_migration from '../../shared/assets/images/cloud-migration.svg';
import devops_auto from '../../shared/assets/images/devOps-auto.svg';
import devops_operations from '../../shared/assets/images/devops-operations.svg';
import security from '../../shared/assets/images/security.svg';
import devops_badge1 from '../../shared/assets/images/devops-badge1.svg';
import devops_badge2 from '../../shared/assets/images/devops-badge2.svg';
import devops_badge3 from '../../shared/assets/images/devops-badge3.svg';
import devops_badge4 from '../../shared/assets/images/devops-badge4.svg';
import devops_badge5 from '../../shared/assets/images/devops-badge5.png';

import google_cloud from '../../shared/assets/images/google-cloud.svg';
import aws from '../../shared/assets/images/aws.svg';
import azure from '../../shared/assets/images/azure.svg';
import contact_img from "../../shared/assets/images/contact-img.png";
import location from "../../shared/assets/images/location.svg";
import phone_icon from "../../shared/assets/images/call.svg";
import user_icon from "../../shared/assets/images/user.svg";
import mail_contact_icon from "../../shared/assets/images/mail-icon.svg";
import phone_contact_icon from "../../shared/assets/images/phone-icon.svg";
import modal_cross from "../../shared/assets/images/cross.svg";
import contact_mob_img from "../../shared/assets/images/contact-mob-img.png";
import about_ashav_mob from "../../shared/assets/images/about-mob-ashav.svg";
import tech_writing from '../../shared/assets/images/tech-writing.svg';
import product_management from '../../shared/assets/images/product-management.svg';
import project_management from '../../shared/assets/images/project-management.svg';
import monitoring from '../../shared/assets/images/monitoring.svg';
import PMI from '../../shared/assets/images/pmi.svg';
import qa_img from '../../shared/assets/images/qa-img.jpg';
import testing_and_auto from '../../shared/assets/images/testing-and-auto.svg';
import API_driven_testing from '../../shared/assets/images/api-driven-testing.svg';
import performance_testing from '../../shared/assets/images/peformance-testing.svg';
import penetration_testing from '../../shared/assets/images/penetration-testing.svg';
import sumo_qa from '../../shared/assets/images/sumo-qa.svg';
import istqb_qa from '../../shared/assets/images/istqb-qa.svg';
import designing_img from '../../shared/assets/images/designing-img.jpg';
import web_designing from '../../shared/assets/images/web-designing.svg';
import app_designing from '../../shared/assets/images/app-designing.svg';
import info_architecture_design from '../../shared/assets/images/info-architecutre-design.svg';
import operations_img from '../../shared/assets/images/operations-img.png';
import core_robo_img from "../../shared/assets/images/vision-mob-img.jpg";
import core_firework_img from "../../shared/assets/images/firework.png";
import no_compromise from "../../shared/assets/images/no-compromise.svg";
import akash_srivastava from "../../shared/assets/images/team_pictures/aakash-srivastava.jpg";
import ajay_rathod from "../assets/images/team_pictures/ajay-rathod.jpg";
import aman_sharma from "../assets/images/team_pictures/aman-sharma.jpg";
import ananya_singh from "../assets/images/team_pictures/ananya-singh.jpg";
import ankita_varshney from "../assets/images/team_pictures/ankita-varshney.jpg";
import anubhav from "../assets/images/team_pictures/anubhav-bhardwaj.jpg";
import arvind from "../assets/images/team_pictures/arvind-kumar-yadav.jpg";
import divesh from "../assets/images/team_pictures/divesh-kumar-singh.jpg";
import divyanshu from "../assets/images/team_pictures/divyanshu-kishan.jpg";
import gaurav from "../assets/images/team_pictures/gaurav-rajpoot.jpg";
import hritik from "../assets/images/team_pictures/hritik-hiranwar.jpg";
import jayant from "../assets/images/team_pictures/jayant-singh.jpg";
import kawaldeep from "../assets/images/team_pictures/kawaldeep-singh.jpg";
import mayank from "../assets/images/team_pictures/mayank-tyagi.jpg";
import mohit from "../assets/images/team_pictures/mohit-yadav.jpg";
import mukul from "../assets/images/team_pictures/mukul-srivastava.jpg";
import nadeem from "../assets/images/team_pictures/nadeem-khan.jpg";
import nishant from "../assets/images/team_pictures/nishant-kaushik.jpg";
import nishita from "../assets/images/team_pictures/nishita-chaudhary.jpg";
import sandeep from "../assets/images/team_pictures/sandeep-jalal.jpg";
import shivani from "../assets/images/team_pictures/shivani-tiwari.jpg";
import shivank from "../assets/images/team_pictures/shivank-chauhan.jpg";
import unique from "../assets/images/team_pictures/unique-sharma.jpg";
import vikas from "../assets/images/team_pictures/vikas-raghav.jpg";
import yash from "../assets/images/team_pictures/yash-chaurasiya.jpg";
import yashita from "../assets/images/team_pictures/yash-chaurasiya.jpg";
import alok from "../assets/images/team_pictures/alok-singh.jpg";
import ashav from "../assets/images/team_pictures/ashav-kaushik.jpg";
import garima from "../assets/images/team_pictures/garima-singh.jpg";
import aakash_tyagi from "../assets/images/team_pictures/aakash-tyagi.png";
import muskan from "../assets/images/team_pictures/muskan-kumari.jpg";
import ankit from "../assets/images/team_pictures/ankit-kumar.jpg";
import lokendra from "../assets/images/team_pictures/lokendra-singh.jpg";
import srishti from "../assets/images/team_pictures/srishti.jpg";
import daksh from "../assets/images/team_pictures/daksh.jpg";
import gaurav_singh from "../assets/images/team_pictures/gaurav-singh.jpg";
import aman_gupta from "../assets/images/team_pictures/aman-gupta.jpg";
import yana from "../assets/images/team_pictures/yana.jpg";
import hemanshi from "../assets/images/team_pictures/hemanshi.jpg";
import suyash from "../assets/images/team_pictures/suyash.jpg";
import pramita from "../assets/images/team_pictures/pramita-ghosh.jpg";
import akash_tyagi from "../assets/images/team_pictures/akash-tyagi.jpg";

import celebration_1 from "../assets/images/Life_at_wmp/celebration-1.jpg";
import celebration_2 from "../assets/images/Life_at_wmp/celebration-2.jpg";
import celebration_3 from "../assets/images/Life_at_wmp/celebration-3.jpg";
import celebration_4 from "../assets/images/Life_at_wmp/celebration-4.jpg";
import celebration_5 from "../assets/images/Life_at_wmp/celebration-5.jpg";
import celebration_6 from "../assets/images/Life_at_wmp/celebration-6.jpg";
import celebration_7 from "../assets/images/Life_at_wmp/celebration-7.jpg";
import celebration_8 from "../assets/images/Life_at_wmp/celebration-8.jpg";
import celebration_9 from "../assets/images/Life_at_wmp/celebration-9.jpg";
import celebration_10 from "../assets/images/Life_at_wmp/celebration-10.jpg";
import celebration_11 from "../assets/images/Life_at_wmp/diwali.jpg";
import celebration_12 from "../assets/images/Life_at_wmp/seniors-grp.jpg";
import celebration_13 from "../assets/images/Life_at_wmp/celebration-13.jpg";
import celebration_14 from "../assets/images/Life_at_wmp/celebration-14.jpg";
import celebration_15 from "../assets/images/Life_at_wmp/celebration-15.jpg";
import celebration_16 from "../assets/images/Life_at_wmp/celebration-16.jpg";
import celebration_17 from "../assets/images/Life_at_wmp/celebration-17.jpg";
import celebration_18 from "../assets/images/Life_at_wmp/celebration-18.jpg";
import celebration_19 from "../assets/images/Life_at_wmp/celebration-19.jpg";
import celebration_20 from "../assets/images/Life_at_wmp/celebration-20.jpeg";
import celebration_21 from "../assets/images/Life_at_wmp/celebration-21.jpg";
import celebration_22 from "../assets/images/Life_at_wmp/celebration-22.jpg";
import celebration_23 from "../assets/images/Life_at_wmp/celebration-23.jpg";

import eric from "../assets/images/eric .png";
import rex from "../assets/images/rex .png";
import kapil from "../assets/images/Kapil.png";
import serge from "../assets/images/serge-lachapelle.png";
import male from "../assets/images/male.jpg";
import female_dummy from "../assets/images/female.jpg";
import leadership_ashav from "../assets/images/leadership-ashav.jpg";
import leadership_nishant from "../assets/images/leadership-nishant.jpg";
import leadership_garima from "../assets/images/leadership-garima.jpg";
import leadership_alok from "../assets/images/leadership-alok.jpg";
import Rhythm_1 from "../assets/images/Life_at_wmp/rhythm-1.jpg";
import Rhythm_2 from "../assets/images/Life_at_wmp/rhythm-2.jpg";
import Rhythm_3 from "../assets/images/Life_at_wmp/rhythm-3.jpg";
import Rhythm_4 from "../assets/images/Life_at_wmp/rhytm-4.jpeg";
import Rhythm_5 from "../assets/images/Life_at_wmp/rhythm-5.jpg";
import Rhythm_6 from "../assets/images/Life_at_wmp/rhythm-6.jpg";
import Rhythm_7 from "../assets/images/Life_at_wmp/rhythm-7.jpg";
import Rhythm_8 from "../assets/images/Life_at_wmp/rhythm-8.jpg";
import Rhythm_9 from "../assets/images/Life_at_wmp/rhythm-9.jpg";
import Rhythm_10 from "../assets/images/Life_at_wmp/rhythm-10.jpg";
import Rhythm_11 from "../assets/images/Life_at_wmp/rhythm-11.jpg";
import Rhythm_12 from "../assets/images/Life_at_wmp/rhythm-12.jpg";
import Rhythm_13 from "../assets/images/Life_at_wmp/rhythm-13.jpg";
import Rhythm_14 from "../assets/images/Life_at_wmp/rhythm-14.jpg";
import Rhythm_15 from "../assets/images/Life_at_wmp/rhythm-15.jpg";
import Rhythm_16 from "../assets/images/Life_at_wmp/rhythm-16.jpg";
import ankita_blog_img from "../assets/images/ankita-blog.jpg";
import anubhav_blog_img_1 from "../assets/images/ux-1st.jpg";
import anubhav_blog_img_2 from "../assets/images/ux-2nd.jpg";
import Dashboard_cxo from "../assets/images/dashbords-cxotulz.png";
import CXOTulz from "../assets/images/cxotulz.svg";
import solution_bullet from "../assets/images/solution-bullet.svg";
import cost_analysis from "../assets/images/cost-analysis.png";
import cloud_switch from "../assets/images/cloud-switch.png";
import login_vector from "../assets/images/login-vector.png";
import vector from "../assets/images/vector.png";
import cxo_report from "../assets/images/cxo-reports.png";
import trusted_partners from "../assets/images/trusted-partnership.svg";
import requirement_scrutiny from "../assets/images/requirement-scrutiny.svg";
import flexible_Payment from "../assets/images/flexible-payment-execution.svg";
import dedicated_resource from "../assets/images/dedicated-resource-allocation.svg";
import centralized_support from "../assets/images/centralized-support.svg"; 
import ownership from "../assets/images/ownership.svg";
import mob_dashboard from "../assets/images/mob-dashboard.svg";
import ankita_blog_gpt from "../assets/images/ankita-gpt-blog.png";
import cxotulz_logo from "../assets/images/cxotulz-logo.svg";
import qufree from "../assets/images/qufree.svg";
import trakwa from "../assets/images/trakwa.svg";
import trakwa_app from "../assets/images/trakwa-app.svg";
import trakwa_simplified from "../assets/images/trakwa-simplified.svg";
import trakwa_thumbnail from "../assets/images/trakwa_thumbnail.jpg";
import trakwa1 from "../assets/images/trakwa1.svg";
import trakwa2 from "../assets/images/trakwa2.svg";
import trakwa3 from "../assets/images/trakwa3.svg";
import qufree_thumbnail from "../assets/images/qufree_thumbnail.jpg";
import qufree_screen from "../assets/images/qufree-screen.svg";
import qu1 from "../assets/images/qu1.svg";
import qu2 from "../assets/images/qu2.svg";
import qu3 from "../assets/images/qu3.svg";
import qu4 from "../assets/images/qu4.svg";
import qu_register from "../assets/images/register.svg";
import qu_signup from "../assets/images/signup.svg";
import qu_phone from "../assets/images/phone.svg";
import tagline from "../assets/images/tagline.svg";
import account_created from "../assets/images/acc-created.png";
import cstm_dashboard from "../assets/images/custm-dasb.png";
import profile_qufree from "../assets/images/profile-setup.svg";
import cxo_thumbnail from "../assets/images/cxo-thumbnail.jpg";
import explore_1 from "../assets/images/explore1.svg";
import explore_2 from "../assets/images/explore2.svg";
import explore_3 from "../assets/images/explore3.svg";
import mob_cxo from "../assets/images/mob_cxo.jpg";
import mob_trakwa from "../assets/images/mob_trakwa.jpg";
import mob_qufree from "../assets/images/mob_qufree.png";
import firebase from "../assets/images/firebase.png";
import docker from "../assets/images/docker.svg";
import psql from "../assets/images/psql.png";
import github from "../assets/images/github_actions.png";
import sendgrid from "../assets/images/sendgrid.png";
import mob_hospitality from "../assets/images/mob_hospitality.png";
import hospitality_story from "../assets/images/hospitality1.png";
import hospitality_thumbnail from "../assets/images/hospitality_thumbnail.jpg";
import hospitality_caraousel from "../assets/images/hospitality_caraousel.jpg";
import mob_healthcare from "../assets/images/mob_healtcare.png";
import healthcare1 from "../assets/images/healthcare1.png";
import healthcare_thumbnail from "../assets/images/healthcare_thumbnail.jpg";
import healthcare_carousel from "../assets/images/healthcare_carousel.jpg";
import transportation_bg from "../assets/images/transportation_bg.svg";
import transportation_thumbnail from "../assets/images/transport_thumbnail.svg";
import transport_card from "../assets/images/transport_card.svg";
import mob_transport from "../assets/images/mob_transport.svg";
import tech_bg from "../assets/images/tech_bg.png";
import tech_thumbnail from "../assets/images/tech-thumbnail.png";
import web_tech from "../assets/images/tech-card.png";
import mob_tech from "../assets/images/mob_tech.png";
import emp_bg from "../assets/images/emp_bg.png";
import emp_thumbnail from "../assets/images/emp_thumbnail.png";
import emp_card from "../assets/images/emp_card.png";
import mob_emp from "../assets/images/mob_emp.png";
import kitchen_bg from "../assets/images/kitchen_bg.png";
import kitchen_thumbnail from "../assets/images/kitchen_thumbnail.png";
import kitchen_card from "../assets/images/kitchen_card.png";
import mob_kitchen from "../assets/images/mob_kitchen.png";
import mob_ngo from "../assets/images/mob_ngo.png";
import ngo_bg from "../assets/images/ngo_bg.png";
import ngo_thumbnail from "../assets/images/ngo_thumbnail.png";
import ngo_card from "../assets/images/ngo_card.png";
import nishant_cxo_blog from "../assets/images/cxo_blog.png";
import blog_cloud_cost from "../assets/images/blog_cloud_cost.jpg";
import location_pin from "../assets/images/location.png";
import { pageRoutes } from './pageRoutes.constant'




export const SHOW_BORDER_SCREEN = 'home';
export const SIDEBAR_ITEMS = {
  WHO_WE_ARE: 'Who We Are',
  SERVICES: 'Services',
  COMPANY: 'Company',
  SOLUTIONS: 'Solutions',
  CONTACT: 'Contact',
  CONTACT_US: 'Contact Us',
  ABOUT_US: 'About Us',
  LEADERSHIP: 'Leadership',
  TEAM: 'Team',
  SOFTWARE_ENGINEERING: 'Software Engineering',
  CLOUD_DEVOPS: 'Cloud & DevOps',
  QUALITY_ASSURANCE: 'Quality Assurance',
  DESIGNING: 'Designing (UI/UX)',
  OPERATIONS: 'Operations',
  BLOGS: 'Blogs',
  SUCCESS_STORIES: 'Success Stories',
  LIFE_AT_WMP: 'Life At Wingman',
  TRAKWA: 'Trakwa',
  QUFREE: 'QuFree',
  CXOTULZ: 'CXOTulz',
  AWARDS: 'Awards'
};
export const IMAGES = {
  BLOG_CLOUD_COST: blog_cloud_cost,
  NISHANT_CXO_BLOG : nishant_cxo_blog,
  NGO_BG: ngo_bg,
  NGO_THUMBNAIL: ngo_thumbnail,
  NGO_CARD: ngo_card,
  MOB_NGO: mob_ngo,
  EMP_BG: emp_bg,
  EMP_THUMBNAIL: emp_thumbnail,
  EMP_CARD: emp_card,
  MOB_EMP: mob_emp,
  KITCHEN_BG :kitchen_bg,
  KITCHEN_THUMBNAIL: kitchen_thumbnail,
  MOB_KITCHEN: mob_kitchen,
  KITCHEN_CARD: kitchen_card,
  TECH_BG: tech_bg,
  TECH_THUMBNAIL: tech_thumbnail,
  TECH_WEB: web_tech,
  MOB_TECH: mob_tech,
  TRANSPORT_BG: transportation_bg,
  TRANSPORT_THUMBNAIL: transportation_thumbnail,
  TRANSPORT_WEB: transport_card,
  MOB_TRANSPORT: mob_transport,
  HEALTHCARE_THUMBNAIL: healthcare_thumbnail,
  HEALTHCARE_CAROUSEL: healthcare_carousel,
  HEALTHCARE_WEB: healthcare1,
  MOB_HEALTHCARE: mob_healthcare,
  HOSPITALITY_CARAOUSEL: hospitality_caraousel,
  MOB_HOSPITALITY: mob_hospitality,
  HOSPITALITY_STORY: hospitality_story,
  HOSPITALITY_THUMBNAIL: hospitality_thumbnail,
  ANKITA_QA_BLOG_IMG: ankita_blog_gpt,
  MOB_CXO: mob_dashboard,
  OWNERSHIP: ownership,
  TRUSTED_PARTNERSHIP: trusted_partners,
  REQUIREMENT_SCRUTINY: requirement_scrutiny,
  FLEXIBLE_PAYMENT: flexible_Payment,
  DEDICATED_RESOURCES: dedicated_resource,
  CENTARLIZED_SUPPORT: centralized_support,
  CXOTULZ: CXOTulz,
  DASHBOARD_CXO : Dashboard_cxo,
  ANUBHAV_BLOG_IMG_1 : anubhav_blog_img_1,
  ANUBHAV_BLOG_IMG_2 : anubhav_blog_img_2,
  ANKITA_BLOG_IMG : ankita_blog_img,
  CELEBRATION_10: celebration_10,
  CELEBRATION_11: celebration_11,
  CELEBRATION_12: celebration_12,
  CELEBRATION_13: celebration_13,
  CELEBRATION_14: celebration_14,
  CELEBRATION_15: celebration_15,
  CELEBRATION_16: celebration_16,
  CELEBRATION_17: celebration_17,
  CELEBRATION_18: celebration_18,
  CELEBRATION_19: celebration_19,
  CELEBRATION_20: celebration_20,
  CELEBRATION_21: celebration_21,
  CELEBRATION_22: celebration_22,
  CELEBRATION_23: celebration_23,


  MALE_DUMMY: male,
  FEMALE_DUMMY: female_dummy,
  ERIC: eric,
  REX: rex,
  KAPIL: kapil,
  SERGE: serge,
  ASHAV_IMG: leadership_ashav,
  OS_ASHAV_IMG: os_ashav_img,
  WELCOME_KIT: os_welcomekit,
  TEAM_IMG: os_team_img,
  TEAM_IMG1: os_team_img2,
  MISSION_IMG: mission_img,
  ROBO_IMG: robo_img,
  GROUP_IMG: card_group,
  GLOBE_IMG: globe,
  NO_COMPORMISE: no_compromise,
  TALENT_GROUP: pool_group,
  TECH_COMPETENCE: tech_competence,
  COST_OPTIMIZATION: cost_optimization,
  AWARDS_IMG: awards_img1,
  CERTIFICATE: awards_img,
  DOT_CIRCLES: circle_dot,
  RED_RECT: red_rect,
  BLUE_RECT: blue_rect,
  PREV: prev,
  NEXT: next,
  CARD_BG: card,
  TEAM_BG: team_bg,
  LEADERSHIP_BG: leadeship_bg,
  LINKDIN_ICON: linkdin_icon,
  MAIL_ICON: mail_icon,
  NISHANT_IMG: leadership_nishant,
  GARIMA_IMG: leadership_garima,
  ALOK_IMG: leadership_alok,
  SQUARE_RED: square_red,
  RECT_BLUE: rect_blue,
  KUB_IMAGE_MV_1: kub_img_mv_1,
  KUB_IMAGE_MV_2: kub_img_mv_2,
  KUB_IMAGE_MV_3: kub_img_mv_3,
  KUB_IMAGE_MV_4: kub_img_mv_4,
  BLOG_CARD_IMG_1: blog_card_img_1,
  BLOG_CARD_IMG_2: blog_card_img_2,
  BLOG_CARD_IMG_3: blog_card_img_3,
  VIKASH_BLOG_IMG: vikas_blog_img,
  JAYANT_BLOG_IMG: jayant_blog_img,
  SAMYAKT_BLOG_IMG: samyakt_blog_img,
  MOHIT_BLOG_IMG: mohit_blog_img,
  UNIQUE_BLOG_IMG: unique_blog_img,
  HARPREET_BLOG_IMG: harpreet_blog_img,
  KAWALDEEP_BLOG_IMG: kawaldeep_blog_img,
  KEKASHRI_BLOG_IMG: kekashri_blog_img,
  MUKUL_BLOG_ING: mukul_blog_img,
  BLOG_CARD_IMG_5: blog_card_img_5,
  BLOG_CARD_IMG_6: blog_card_img_6,
  NETWORK_BG: network_management_bg,
  TECHNOLOGY_BG: technology_bg,
  NETWORK_WEB: network_mgt_web,
  TECHNOLOGY_WEB: technology_web,
  NETWORK_MOB: network_mgt_mob,
  TECHNOLOGY_MOB: technology_mob,
  CULTURE_PEOPLE: culture_people_img,
  CULTURE_INNOVATION: culture_innovation_img,
  CULTURE_BUILDING: culture_building_img,
  CULTURE_FAMILY: culture_family_img,
  CULTURE_PEOPLE_MOB: culture_people_mob,
  CULTURE_INNOVATION_MOB: culture_innovation_mob,
  CULTURE_BUILDING_MOB: culture_building_mob,
  CULTURE_FAMILY_MOB: culture_family_mob,
  CONTACT_IMG: contact_img,
  LOCATION_ICON: location,
  PHONE_ICON: phone_icon,
  USER_ICON: user_icon,
  MAIL_CONTACT_ICON: mail_contact_icon,
  PHONE_CONTACT_ICON: phone_contact_icon,
  MODAL_CROSS: modal_cross,
  CONTACT_MOB_IMG: contact_mob_img,
  ABOUT_MOB_ASHAV: about_ashav_mob,
  CORE_ROBO_IMG: core_robo_img,
  CORE_FIREWORK_IMG: core_firework_img,
  NAV_FOOTER_LINKDIN: Linkdein,
  NAV_FOOTER_INSTAGRAM: Instagram,
  NAV_FOOTER_FACEBOOK: facebook,
  AAKASH: akash_srivastava,
  AJAY: ajay_rathod,
  ALOK: alok,
  AMAN: aman_sharma,
  ANANYA: ananya_singh,
  ANKITA: ankita_varshney,
  ANUBHAV: anubhav,
  ARVIND: arvind,
  ASHAV: ashav,
  DIVESH: divesh,
  DIVYANSHU: divyanshu,
  GARIMA: garima,
  GAURAV: gaurav,
  HRITIK: hritik,
  JAYANT: jayant,
  KAWALDEEP: kawaldeep,
  MAYANK: mayank,
  MOHIT: mohit,
  MUKUL: mukul,
  NADEEM: nadeem,
  NISHANT: nishant,
  NISHITA: nishita,
  SANDEEP: sandeep,
  SHIVANI: shivani,
  SHIVANK: shivank,
  UNIQUE: unique,
  VIKAS: vikas,
  YASH: yash,
  YASHITA: yashita,
  AAKASH_TYAGI: aakash_tyagi,
  SRISHTI: srishti,
  MUSKAN: muskan,
  ANKIT: ankit,
  LOKENDRA: lokendra,
  DAKSH: daksh,
  GAURAV_SINGH: gaurav_singh,
  AMAN_GUPTA: aman_gupta,
  YANA: yana,
  HEMANSHI: hemanshi,
  SUYASH:suyash,
  AKASH_TYAGI: akash_tyagi,
  PRAMITA: pramita,
  CELEBRATION_1: celebration_1,
  CELEBRATION_2: celebration_2,
  CELEBRATION_3: celebration_3,
  CELEBRATION_4: celebration_4,
  CELEBRATION_5: celebration_5,
  CELEBRATION_6: celebration_6,
  CELEBRATION_7: celebration_7,
  CELEBRATION_8: celebration_8,
  CELEBRATION_9: celebration_9,
  RHYTHM_1: Rhythm_1,
  RHYTHM_2: Rhythm_2,
  RHYTHM_3: Rhythm_3,
  RHYTHM_4: Rhythm_4,
  RHYTHM_5: Rhythm_5,
  RHYTHM_6: Rhythm_6,
  RHYTHM_7: Rhythm_7,
  RHYTHM_8: Rhythm_8,
  RHYTHM_9: Rhythm_9,
  RHYTHM_10: Rhythm_10,
  RHYTHM_11: Rhythm_11,
  RHYTHM_12: Rhythm_12,
  RHYTHM_13: Rhythm_13,
  RHYTHM_14: Rhythm_14,
  RHYTHM_15: Rhythm_15,
  RHYTHM_16: Rhythm_16,
};

// images
export const images = {
  location_pin: location_pin,
  MOB_CXO: mob_cxo,
  MOB_TRAKWA: mob_trakwa,
  MOB_QUFREE: mob_qufree,
  EXPLORE_1: explore_1,
  EXPLORE_2: explore_2,
  EXPLORE_3: explore_3,
  CXO_THUMBNAIL: cxo_thumbnail,
  PROFILE_QU: profile_qufree,
  ACC_CREATED: account_created,
  CSTM_DASH: cstm_dashboard,
  TAGLINE: tagline,
  QU1: qu1,
  QU2: qu2,
  QU3: qu3,
  QU4: qu4,
  QU_REGISTER:qu_register,
  QU_SIGNUP: qu_signup,
  QU_PHONE: qu_phone,
  QUFREE_SCREEN: qufree_screen,
  QUFREE_THUMBNAIL: qufree_thumbnail,
  TRAKWA1: trakwa1,
  TRAKWA2: trakwa2,
  TRAKWA3: trakwa3,
  TRAKWA_THUMBNAIL:trakwa_thumbnail,
  TRAKWA_APP: trakwa_app,
  TRAKWA_SIMPLIFIED: trakwa_simplified,
  cxotulzLogo: cxotulz_logo,
  Qufree: qufree,
  Trakwa: trakwa,
  CxoReport: cxo_report,
  loginvector: login_vector,
  Vector: vector,
  costAnalysis: cost_analysis,
  cloudSwitch: cloud_switch,
  solutionBullet: solution_bullet,
  cardImg: card_img,
  blue_box: blueBox,
  circles: Circles,
  net_img: netImg,
  Prev: prev,
  Next: next,
  blue_bg: blueBg,
  sde: SDE,
  operations: Operations,
  UI: ui,
  Cloud: cloud,
  QA: qa,
  Consulting: consulting,
  Hospitality: hospitality,
  Group: group,
  Technology: technology,
  Transportaion: transportaion,
  dark_blue_box: darkBlueBox,
  red_box: redBox,
  circles_vert: circlesVert,
  red_vert_line: redVertLine,
  SE_bg: SeBg,
  SE_Img: SeImg,
  mob_app_dev: mobAppDev,
  web_app_dev: webAppDev,
  soft_engg: softwareEngg,
  product_engg: productEngg,
  poc: POC,
  quote: Quotes,
  logo: wmpLogo,
  bullet: BULLET,
  SERVICES_FOOTER_BG: services_footer_img,
  DEVOPS_BG: devops_bg,
  DEVOPS_IMG: devops_img,
  QA_IMG: qa_img,
  DESIGNING_IMG: designing_img,
  OPERATIONS_IMG: operations_img,
  SCROLL_DOWN: scroll_down,
  RIGHT_ARROW: right_arrow,
}

export const FIVE_THOUSAND_CONST = 5000;
export const SEVEN_THOUSAND_CONST = 7000;
export const TWO_THOUSAND_CONST = 2000;
export const ONE_THOUSAND = 1000;
export const EIGHT_HUNDRED = 800;

//home page consts
export const carouselImages = [landing1, landing2, landing3];
export const caraouselImagesMob = [landing1_mob, landing2_mob, landing3_mob];
// home page const ends


//case Study const
export const carouselImagesCaseStudy = [netImg, technology_carouel, hospitality_caraousel, healthcare_carousel, transportation_thumbnail, tech_thumbnail, emp_thumbnail, kitchen_thumbnail, ngo_thumbnail];
// cast study consts ends


// Services const
export const gifs = [
  { path: SDE, val: "Software Engineering", routeLink: pageRoutes.SoftwareEngineering },
  { path: cloud, val: "Cloud & DevOps", routeLink: pageRoutes.DevOps },
  { path: qa, val: "Quality Assurance", routeLink: pageRoutes.QA },
  { path: ui, val: "Designing (UI/UX)", routeLink: pageRoutes.Designing },
  { path: Operations, val: "Operations", routeLink: pageRoutes.Operations }
];
// services consts ends


// testimonials consts
export const headerImages = [consulting, group, technology, hospitality, transportaion, healthcare, realEstate, engage, organization, Food];
export const partnerImages = [IMAGES.ERIC, IMAGES.SERGE, IMAGES.REX, IMAGES.KAPIL];
// testimonials consts end


//Blogs consts starts
export const dataBlogs = [
  {
    by: "Daksh and Hrithik",
    date: "04 June, 2024",
    URL: "https://medium.com/@besocial_27455/expert-guide-to-cloud-cost-optimization-for-reduced-expenses-8a964e09c7cb",
    UrlDesc:
      "Expert Guide to Cloud Cost Optimization for Reduced Expenses",
    image: IMAGES.BLOG_CLOUD_COST
  },
  {
    by: "Nishant Kaushik",
    date: "02 May, 2024",
    URL: "https://medium.com/@besocial_27455/leveraging-microservices-in-cloud-native-architecture-5bd0a77c5b28",
    UrlDesc:
      "Leveraging Microservices in Cloud-Native Architecture",
    image: IMAGES.NISHANT_CXO_BLOG
  }
];
//blogs const ends

//Software Engg consts
export const cardData = [
  { URL: webAppDev, desc: 'Web App Development', routeLink: pageRoutes.WebAppDev },
  { URL: mobAppDev, desc: 'Mobile App Development', routeLink: pageRoutes.MobileAppDev },
  { URL: productEngg, desc: 'Product Reengineering', routeLink: pageRoutes.ProductReEngg },
  { URL: softwareEngg, desc: 'Software Integration', routeLink: pageRoutes.SoftwareIntegration },
  { URL: POC, desc: 'Proof Of Concepts', routeLink: pageRoutes.POC }
];


export const technologyImages = [
  { name: 'Java', url: Java },
  { name: 'Python', url: Python },
  { name: 'Node', url: Node },
  { name: 'React Native', url: ReactNative },
  { name: 'HTML 5', url: HTML5 },
  { name: 'CSS 3', url: CSS3 },
  { name: 'Vue', url: Vue },
  { name: 'Angular', url: Angular }
];

export const techstack = [
  { name: 'Node', url: Node },
  { name: 'React Native', url: ReactNative },
  { name: 'Docker', url: docker },
  { name: 'Github Actions', url: github },
  { name: 'Amazon Web Services', url: aws },
  { name: 'PostgreSQL', url: psql },
  { name: 'Angular', url: Angular },
  {name: 'FireStore', url: firebase}
];

export const cxoTechStack = [
  { name: 'Node', url: Node },
  { name: 'React Native', url: ReactNative },
  { name: 'Docker', url: docker },
  { name: 'Github Actions', url: github },
  { name: 'Amazon Web Services', url: aws },
  { name: 'PostgreSQL', url: psql }
];

export const qufreeTechStack = [
  { name: 'Node', url: Node },
  { name: 'React Native', url: ReactNative },
  { name: 'Docker', url: docker },
  { name: 'Github Actions', url: github },
  { name: 'Amazon Web Services', url: aws },
  { name: 'PostgreSQL', url: psql },
  { name: 'sendGrid', url: sendgrid },
  { name: 'FireStore', url: firebase}
];
export const carouselTechOptions = {
  loop: true,
  autoplay: true,
  autoplayTimeout: ONE_THOUSAND, // Set the autoplay timeout in milliseconds
  animteOut: true,
  animateIn: true,
  dots: false,
  autoplaySpeed: EIGHT_HUNDRED,    // Set the speed of the automatic transition (in milliseconds)
};
// Software Engg consts ends

// DevOps consts
export const cardDataDevOps = [
  { URL: cloud_infra, desc: 'Cloud Infrastructure Consulting', routeLink: pageRoutes.CloudInfra },
  { URL: cloud_migration, desc: 'Cloud Migration', routeLink: pageRoutes.CloudMigration },
  { URL: devops_auto, desc: 'DevOps Automation', routeLink: pageRoutes.DevOpsAuto },
  { URL: devops_operations, desc: 'DevOps Operations', routeLink: pageRoutes.DevopsOperations },
  { URL: security, desc: 'Security & Cost Optimization', routeLink: pageRoutes.Security },
]

export const teamDevopsImages = [devops_badge1, devops_badge2, devops_badge3, devops_badge4, devops_badge5];

export const devopsExpertiseImages = [
  { name: 'GCP', url: google_cloud },
  { name: 'AWS', url: aws },
  { name: 'Azure', url: azure },
];
//DevOps const ends

//Operations consts
export const cardDataOperations = [
  { URL: tech_writing, desc: 'Technical Writing', routeLink: pageRoutes.TechManagement },
  { URL: project_management, desc: 'Project Management ', routeLink: pageRoutes.ProjectManagement },
  { URL: product_management, desc: 'Product Strategy & Management', routeLink: pageRoutes.ProductManagement },
  { URL: monitoring, desc: 'Level 3 Support (L3: 24x7)', routeLink: pageRoutes.Support },
]

export const teamOperationsImages = [PMI];

//Operations consts ends

//QA const 
export const cardDataQA = [
  { URL: testing_and_auto, desc: 'Testing & Automation', routeLink: pageRoutes.TestingAndAutomation },
  { URL: API_driven_testing, desc: 'API Driven Testing', routeLink: pageRoutes.ApiDrivenTesting },
  { URL: performance_testing, desc: 'Performance Testing', routeLink: pageRoutes.PerformanceTesting },
  { URL: penetration_testing, desc: 'Penetration Testing', routeLink: pageRoutes.PenetrationTesting },
]

export const teamQAImages = [sumo_qa, istqb_qa];
//QA const ends

//Success Stories const 
export const cardDataSuccessStories = [
  {URL: netImg, heading: 'Network Management', desc: 'Our client, a cutting-edge Network Management Solution, required a reliable and efficient partner to ensure the seamless functioning of its services..... ', routeLink: pageRoutes.NetworkManagement},
  {URL: technology_carouel, heading: 'IoT Security Solutions', desc: "A global technology company that provides the world's most valuable brands with innovative merchandising,security, and IoT solutions.....", routeLink: pageRoutes.Technology},
  {URL: hospitality_caraousel, heading: 'Hospitality', desc: "A Hotels Corporation is an American multinational hospitality giant that manages and franchises luxury........", routeLink: pageRoutes.Hospitality},
  {URL: healthcare_carousel, heading: 'Healthcare', desc: "A rigorous, scientifically validated platform that collects continuous streams of data with precision and accuracy........", routeLink: pageRoutes.Healthcare},
  {URL: transportation_thumbnail, heading: 'Transportation', desc: "An upcoming startup that is revolutionizing the rideshare community by offering an economical option to connect riders and ........", routeLink: pageRoutes.Transportation},
  {URL: tech_thumbnail, heading: 'Tech-Enabled Spaces', desc: "The company emerged as a trailblazer in the Indian commercial space sector, offering a tech-enabled network of flexible ........", routeLink: pageRoutes.TechSpaces},
  {URL: emp_thumbnail, heading: 'Employment and Community Engagement', desc: "Our partner is a dynamic social platform designed to accelerate work-life happiness. With a focus on connecting job seekers ........", routeLink: pageRoutes.EmpCommunity},
  {URL: kitchen_thumbnail, heading: 'Kitchen and Dining', desc: "Our partner, the largest F&B marketplace in the South Asia Region, serves as a pivotal platform showcasing a diverse array of dining products........", routeLink: pageRoutes.KitchenDining},
  {URL: ngo_thumbnail, heading: 'Non-Governmental Organization', desc: "The Foundation, a non-government organization (NGO), is dedicated to the preservation of nature and the improvement of living conditions for the less fortunate ........", routeLink: pageRoutes.NonGovOrg},
]
//Success Stories const ends

//Designing const 
export const cardDataDesigning = [
  { URL: web_designing, desc: 'Web Designing', routeLink: pageRoutes.WebDesigning },
  { URL: app_designing, desc: 'App Designing', routeLink: pageRoutes.AppDesigning },
  { URL: info_architecture_design, desc: 'Information Architecture Design', routeLink: pageRoutes.InfoArchitectureDesign },
]
//Designing const ends
//Footer consts
export const socialMediaImg = [
  { url: facebook, link: "https://www.facebook.com/wingmanpartners" },
  { url: Linkdein, link: "https://www.linkedin.com/company/wingmanpartners/?viewAsMember=true" },
  { url: mail, link: "mailto:connect@wingmanpartners.com" },
  { url: Instagram, link: "https://www.instagram.com/wingmanpartners/" },
];
//Footer consts end


//For Scroll to the top
export const scrollToTop = () => {
  setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
};

